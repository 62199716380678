import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import moment from "moment";
import { navigate } from "gatsby-link";
import firebase from "../../config/firebase";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

import AppLayout from "../components/appLayout";

// markup
const Dashboard = () => {
  const dispatch = React.useContext(GlobalDispatchContext);
  const state = React.useContext(GlobalStateContext);

  const [invoices, setInvoices] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  // Make sure user is logged in
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setLoadingData(false);
      } else {
        navigate("/login");
      }
    });
  }, []);

  useEffect(() => {
    if (state.userToken) {
      axios
        .get(`${process.env.GATSBY_API_BASE}/invoices`, {
          headers: {
            Authorization: `Bearer ${state.userToken}`,
          },
        })
        .then((response) => {
          setInvoices(response.data.invoices);
        });
    }
  }, [state.userToken]);

  return (
    <>
      {!loadingData && (
        <AppLayout>
          <Helmet>
            <title>Invoices</title>
          </Helmet>
          <div className="py-10">
            <header>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold leading-tight text-gray-900">
                  Invoices
                </h1>
                <p class="mt-2 max-w-2xl text-md text-gray-500">
                  You can see all of your invoices below.
                </p>
              </div>
            </header>
            <main>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                {/* Replace with your content */}
                <div className=" py-8 sm:px-0">
                  <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Date
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Product Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Amount
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Receipt
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {invoices &&
                                invoices.map((invoice, index) => (
                                  <tr
                                    key={index}
                                    className={
                                      index % 2 === 0
                                        ? "bg-white"
                                        : "bg-gray-50"
                                    }
                                  >
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                      {moment(invoice.createdAt).format(
                                        "MM/DD/YYYY"
                                      )}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                                      {invoice.planName}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                      ${invoice.saleGross}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                      <a
                                        href={invoice.receiptUrl}
                                        className="text-indigo-600 hover:text-indigo-900"
                                        target="_blank"
                                        rel="noopener"
                                      >
                                        View
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {invoices.length <= 0 && (
                            <div className="bg-white text-center text-gray-300 text-xl py-3">
                              You have no invoices to display.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /End replace */}
              </div>
            </main>
          </div>
        </AppLayout>
      )}
    </>
  );
};

export default Dashboard;
